import React from 'react';
import { useParams } from 'react-router-dom';
import industries from '../../JsonFiles/Industries.json';
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import AllProjects from '../../components/AllProjects/AllProjects';
import AllFaqs from '../../components/AllFaqs/AllFaqs';

const IndustriesPages = () => {
  const { id } = useParams();
  const data = industries.find(page => page.id === parseInt(id));

  if (!data) {  // Corrected from "date" to "data"
    return <div>Page not found</div>;
  }

  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <AllProjects cards={projects} />
      <AllFaqs data={data.faqs} />
    </div>
  )
}

export default IndustriesPages