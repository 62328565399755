import React from "react";

const AssetsSVG = ({ hoveredLayer, setHoveredLayer }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 540.71 380.53"
    style={{ display: "block", margin: "auto" ,marginLeft: "5px",marginTop: "-50px"}} // Centering the SVG
    width="90%" // Optional: make it responsive
    height="auto" // Optional: maintain aspect ratio
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: ${hoveredLayer === 1 ? "#000" : "url(#radial-gradient)"}; }
          .cls-2 { fill: ${hoveredLayer === 2 ? "#000" : "url(#radial-gradient-2)"}; }
          .cls-3 { fill: ${hoveredLayer === 3 ? "#000" : "url(#radial-gradient-3)"}; }
          .cls-4 { fill: ${hoveredLayer === 4 ? "#000" : "url(#radial-gradient-4)"}; }
          .cls-5 { fill: ${hoveredLayer === 5 ? "#000" : "url(#radial-gradient-5)"}; }
          .hovered { opacity: 0.5; }
        `}
      </style>
      <radialGradient id="radial-gradient" cx="165.02" cy="162.17" r="93.55" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d0a8ce"/>
        <stop offset="0.37" stopColor="#cfa4cc"/>
        <stop offset="0.73" stopColor="#cc99c5"/>
        <stop offset="1" stopColor="#c98abc"/>
      </radialGradient>
      <radialGradient id="radial-gradient-2" cx="410.71" cy="-80.55" r="129.22" gradientTransform="translate(866.13 233.28) rotate(180)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#c2c4c6"/>
        <stop offset="0.51" stopColor="#c0c2c4"/>
        <stop offset="0.69" stopColor="#b9bbbd"/>
        <stop offset="0.82" stopColor="#aeb0b2"/>
        <stop offset="0.92" stopColor="#9d9fa1"/>
        <stop offset="1" stopColor="#8a8c8e"/>
      </radialGradient>
      <radialGradient id="radial-gradient-3" cx="168.53" cy="307.59" r="96.09" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#73ae43"/>
        <stop offset="0.29" stopColor="#67a540"/>
        <stop offset="0.81" stopColor="#498d37"/>
        <stop offset="1" stopColor="#3c8333"/>
      </radialGradient>
      <radialGradient id="radial-gradient-4" cx="358.66" cy="1052.86" r="96.09" gradientTransform="translate(774.03 1215.03) rotate(180)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#d22a27"/>
        <stop offset="0.29" stopColor="#cf2a27"/>
        <stop offset="0.52" stopColor="#c52a25"/>
        <stop offset="0.74" stopColor="#b42a23"/>
        <stop offset="0.93" stopColor="#9d291f"/>
        <stop offset="1" stopColor="#93291e"/>
      </radialGradient>
      <radialGradient id="radial-gradient-5" cx="313.37" cy="241.48" r="63.88" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#4359a7"/>
        <stop offset="1" stopColor="#3d5ba9"/>
      </radialGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className={`cls-1 ${hoveredLayer === 1 ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredLayer(1)}
          d="M51,228.62l70.63.61s-2.76-43.26,44.78-60.84c0,0,50.45-16.51,77.66,32.87,0,0,5.81-35.93,35-67.88,0,0-63-61.3-143.7-28.73C135.36,104.65,55.11,137.51,51,228.62Z"
        />
        <path
          className={`cls-2 ${hoveredLayer === 2 ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredLayer(2)}
          d="M533.69,237.3l-70.63-.61s2.76,43.26-44.79,60.84c0,0-50.44,16.51-77.65-32.87,0,0-5.81,35.93-35,67.88,0,0,63,61.29,143.7,28.73C449.31,361.27,529.56,328.41,533.69,237.3Z"
        />
        <path
          className={`cls-3 ${hoveredLayer === 3 ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredLayer(3)}
          d="M51.2,239h70s-1.84,46.32,46.47,63.74c0,0,32.25,13.3,63.44-14.67L285.85,332s-34.39,43.72-96,44c0,0-50.44,4.28-93.4-34.55C96.45,341.46,52.66,305.69,51.2,239Z"
        />
        <path
          className={`cls-4 ${hoveredLayer === 4 ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredLayer(4)}
          d="M532.69,230.73h-70s1.83-46.32-46.48-63.75c0,0-32.25-13.3-63.44,14.68L298,137.78s34.39-43.72,96-44c0,0,50.44-4.28,93.4,34.55C487.44,128.31,531.24,164.08,532.69,230.73Z"
        />
        <path
          className={`cls-5 ${hoveredLayer === 5 ? "hovered" : ""}`}
          onMouseEnter={() => setHoveredLayer(5)}
          d="M291.36,142.43l54.11,45.63S326.9,199.52,326,241c0,0-.23,30.72-16.28,57.32A206.22,206.22,0,0,1,291.36,325l-55.26-44s16.51-11,19.94-43.79c0,0,1.61-36,9.64-53.2C265.68,183.93,275.54,160.77,291.36,142.43Z"
        />
      </g>
    </g>
  </svg>
);

export default AssetsSVG;
