import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "./OfficeImages.css";

function OfficeImages({ image }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);


  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);


  const slideNext = useCallback(() => {
    setActiveIndex((val) => {
      if (val >= image.length - 1) {
        return 0;
      } else {
        return val + 1;
      }
    });
  }, [image.length]);

  const slidePrev = () => {
    setActiveIndex((val) => {
      if (val <= 0) {
        return image.length - 1;
      } else {
        return val - 1;
      }
    });
  };

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 30000)
      );
    }
  }, [slideDone, slideNext]);



  const AutoPlayStop = () => {
    if (timeID > 0) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  return (
    <div className="OfficeImageContain">
      <motion.div
        className="animated-section"
        ref={ref}
        initial={{ opacity: 0, y: 60 }}
        animate={animationControls}
      >
        <div
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
    >

      
      {image.map((item, index) => {
        return (
          <div
            className={"slider__item slider__item-active-" + (activeIndex + 1)}
            key={index}
          >
            {item}
          </div>
        );
      })}
  
      <div className="container__slider__links">
        {image.map((item, index) => {
          return (
            <button
              key={index}
              className={
                activeIndex === index
                  ? "container__slider__links-small container__slider__links-small-active"
                  : "container__slider__links-small"
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveIndex(index);
              }}
            ></button>
          );
        })}
      </div>
  
      <button className="slider__btn-next" onClick={(e) => {
        e.preventDefault();
        slideNext();
      }}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button className="slider__btn-prev" onClick={(e) => {
        e.preventDefault();
        slidePrev();
      }}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    </div>
      </motion.div>
    </div>
    
  );
  
}

export default OfficeImages;
