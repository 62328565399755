import React, { useState, useEffect } from 'react';
import './CareerHiring.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from '../Button/Button';

const CareerHiring = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  const goToPrevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
  };

  const goToNextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 60 }}
      animate={animationControls}
    >
      <h1 className='HiringcardSlider-Heading'>CURRENT OPENINGS</h1>

      <div className="Hiringcard-slider">
        <button className="Hiringslider-button left" aria-label="Previous slide" onClick={goToPrevCard}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="Hiringcard-container">
          <div className="Hiringcard-about active">
            <div className="Hiringright-section">
              <div className="Hiringcard-content">
                <h2>{cards[currentIndex].position}</h2>
                <p className='HiringParaExperience'>Experience: {cards[currentIndex].Experience}</p>
                <p className='HiringParaQualification'>Qualification: {cards[currentIndex].Qualification}</p>
                <p className='HiringParaLocation'>Location: {cards[currentIndex].Location}</p>
                <p className='HiringParaDetails'>{cards[currentIndex].details}</p>
                {/* <Link to="/contact"><button className='contactus-Button'>Apply Now<FontAwesomeIcon icon={faUser} className='icon-ContactUs' /></button></Link> */}
                <Button to="/contact" icon={faUser}>Apply Now</Button>
              </div>
            </div>
          </div>
        </div>
        <button className="Hiringslider-button right" aria-label="Next slide" onClick={goToNextCard}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className='Hiringdots-container'>
        <div className="Hiringdots">
          {cards.map((_, index) => (
            <span
              key={index}
              className={index === currentIndex ? 'Hiringdash active' : 'Hiringdash'}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CareerHiring;
