import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NavLinks from "./NavLinks";
import LazyLoad from 'react-lazyload';
// import "./NavBars.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import "./NavBar.css";

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkScroll = () => {
      if (location.pathname === "/") {
        setIsScrolled(window.scrollY > 100);
      } else {
        setIsScrolled(window.scrollY > 0);  // To detect scrolling on non-home pages as well
      }
    };

    checkScroll();  // Initialize state on mount or location change

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [location]);

  return (
    <nav id="animated-navbar" className={`sticky top-0 z-50 transition-colors duration-500 ${isScrolled ? 'shadow-lg sm:shadow-xl md:shadow-2xl lg:shadow-3xl xl:shadow-4xl bg-white' : 'bg-[var(--primary-color)]'}`}>
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-2 2xl:w-auto w-full flex justify-between">
          <Link to="/">
            <LazyLoad height={200} offset={100} once>
              <img
                src="/images/logo.webp"
                alt="logo"
                className="w-16 sm:w-6 md:w-18 lg:w-20 xl:w-22"
              />
            </LazyLoad>
          </Link>
          <div className={`text-5xl 2xl:hidden`} onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={open ? faTimes : faBars} color={open ? undefined : (isScrolled ? 'black' : 'white')} />
          </div>
        </div>
        <ul className={`2xl:flex hidden uppercase items-center gap-12 font-[Poppins] ${isScrolled ? 'text-black' : 'text-white'}`}>
          <li>
            <Link to="/about" className="py-7 px-2 inline-block hover:text-[#922622]">
              Why US?
            </Link>
          </li>
          <NavLinks isScrolled={isScrolled} />
          <li>
            <Link to="/sustainability" className="py-7 px-2 inline-block hover:text-[#922622]">
              Sustainability
            </Link>
          </li>
          <li>
            <Link to="/career" className="py-7 px-2 inline-block hover:text-[#922622]">
              Career
            </Link>
          </li>
          <li>
            <Link to="/news" className="py-7 px-2 inline-block hover:text-[#922622]">
              NewRoom
            </Link>
          </li>
          <li>
            <Link to="/contact" className="py-7 px-2 inline-block hover:text-[#922622]">
              Contact
            </Link>
          </li>
        </ul>
        <div className="2xl:block hidden"></div>

        {/* mobile navbar */}
        <ul
          className={`
            2xl:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
            duration-500 ${open ? "left-0" : "left-[-100%]"}
          `}
        >
          <li>
            <Link to="/about" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
              Why US?
            </Link>
          </li>
          <NavLinks handleLinkClick={handleLinkClick} />
          <li>
            <Link to="/sustainability" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
              Sustainability
            </Link>
          </li>
          <li>
            <Link to="/career" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
              Career
            </Link>
          </li>
          <li>
            <Link to="/news" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
              NewsRoom
            </Link>
          </li>
          <li>
            <Link to="/contact" className="py-7 px-2 inline-block hover:text-[#922622]" onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
          <li>
            <div className="py-5"></div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
