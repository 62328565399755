import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import ProjectCardRow from '../../components/ProjectCardRow/ProjectCardRow';
import HomeIconSlider from '../../components/HomeIconSlider/HomeIconSlider';


const Projects = () => {
  const data = totalData[7];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <HomeIconSlider data={data.icons}/>
      <ProjectCardRow data={projects} />
    </div>
    
  )
}

export default Projects