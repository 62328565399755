import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './Home.css'; // Import the CSS file

import TotalData from "../../JsonFiles/TotalData.json";
import projects from "../../JsonFiles/Projects.json";
import services from "../../JsonFiles/Services.json";
import industries from "../../JsonFiles/Industries.json";
import news from "../../JsonFiles/News.json";
const HeroIntro = lazy(() => import('../../components/HeroIntro/HeroIntro'));
const HomeAbout = lazy(() => import('../../components/HomeAbout/HomeAbout'));
const HomeQuote = lazy(() => import('../../components/HomeQuote/HomeQuote'));
const AllCapabilities = lazy(() => import('../../components/AllCapabilities/AllCapabilities'));
const AllIndustries = lazy(() => import('../../components/AllIndustries/AllIndustries'));
const AllProjects = lazy(() => import('../../components/AllProjects/AllProjects'));
const HomePartners = lazy(() => import('../../components/HomePartners/HomePartners'));
const AllContactUs = lazy(() => import('../../components/AllContactUs/AllContactUs'));



function Home() {
  // const highlightsData = TotalData.map(data => data.highlights);

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Priya Infra",
    "url": "http://localhost:3000/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "http://localhost:3000/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const servicesStructuredData = services.map(service => ({
    "@context": "http://schema.org",
    "@type": "Service",
    "serviceType": service.title,
    "provider": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://innotechconsultant.co.in/"
    }
  }));
  
  const projectsStructuredData = projects.map(project => ({
    "@context": "http://schema.org",
    "@type": "Project",
    "name": project.title,
    "url": `https://innotechconsultant.co.in/projects/${project.id}`,
    "description": project.description,
    "image": project.image
  }));
  
  const allStructuredData = [...servicesStructuredData, ...projectsStructuredData];
  const HeroData = TotalData[3];
  
  return (
    <div className="home-container">
      <Helmet>
        <title>Home Page - Priya Infra</title>
        <meta name="description" content="Welcome to the homepage of Priya Infra. Welcome to Our Story: Crafting Tomorrow's Vision Today." />
        <meta property="og:title" content="Home Page - Priya Infra" />
        <meta property="og:description" content="Welcome to the homepage of Priya Infra. Learn about our services, projects, and sustainability efforts." />
        <meta property="og:image" content="/images/logo.webp" />
        <meta property="og:url" content="https://innotechconsultant.co.in/" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(allStructuredData)}
        </script>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <HeroIntro data={HeroData} capabilities={services} industries={industries}/>
        <HomeAbout data={HeroData} news={news}/>
        <HomeQuote data={HeroData}/>
        <HomePartners data={HeroData}/>
        <AllCapabilities title={HeroData.capabilitiesTitles} description={HeroData.capabilitiesDescription} capabilities={services}  CurrentPage={HeroData.LinkCapabilities}/>
        <AllIndustries title={HeroData.industriesTitle} description={HeroData.industriesDescription} Industries={industries} CurrentPage={HeroData.LinkIndustries}/>
        <AllProjects cards={projects} />
        <AllContactUs data={HeroData}/>
      </Suspense>
    </div>
  );
}

export default Home;
