import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import CareersJobs from '../../components/CareersJobs/CareersJobs';
import CareerHiring from '../../components/CareerHiring/CareerHiring';
import OfficeImages from '../../components/OfficeImages/OfficeImages';


const Career = () => {
  const data = totalData[10];

  const imageElements = data.OfficeImages.map((imageObj, index) => (
    <img key={index} src={imageObj.image} alt="officeimage" />
  ));
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <OfficeImages image={imageElements} />
      <CareersJobs/>
      <CareerHiring cards={data.CareerHiring} />
    </div>
    
  )
}
export default Career