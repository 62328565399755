import React, {useEffect } from 'react';
import './AllSustainability.css'; // Import the CSS file
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from '../Button/Button';


function AllSustainability({data}) {


  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  return (
    <div className="sustainability-container">
      <motion.div
        className="animated-section"
        ref={ref}
        initial={{ opacity: 0, y: 60 }}
        animate={animationControls}
      >
        <div className='conatin-sustainability'>
      <div className='left-sustainability'>
  <div className='image-containsustain'>
    <img src={data.sustainabilityimage} alt='' className='img-sustainability'></img>
  </div>
</div>
        <div className='right-sustainability'>
        <h1 className="sustainability-title">{data.sustainabilityHeader}</h1>
      <p className="sustainability-description">{data.sustainabilityPara}</p>
      {/* <Link to={data.sustainabilityLink} className='link-sustain'>{data.sustainabilityLinkPara} <FontAwesomeIcon icon={faArrowRight} className="icon-arrowsustain" /></Link> */}
      <div className='link-sustain'>
      <Button to={data.sustainabilityLink}>{data.sustainabilityLinkPara}</Button>
      </div>
        </div>
      </div>
      </motion.div>
      
    </div>
  );
}

export default AllSustainability;
