import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllValues from '../../components/AllValues/AllValues';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import CompanyPdfViewer from '../../components/CompanyPdfViewer/CompanyPdfViewer';
import AllSustainability from '../../components/AllSustainability/AllSustainability';
import AllProjects from '../../components/AllProjects/AllProjects';
import AllMapOffice from '../../components/AllMapOffice/AllMapOffice';
import CompanyDirectors from '../../components/CompanyDirectors/CompanyDirectors';
import CompanyTimeline from '../../components/CompanyTimeline/CompanyTimeline';

const About = () => {
  const data = totalData[4];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <CompanyPdfViewer data={data.PdfCompany}/>
      <AllValues data={data}/>
      <AllSustainability data={data}/>
      <AllProjects cards={projects} />
      <CompanyTimeline data={data.Timeline} misc={data}/>
      <AllMapOffice data={data.MapCity} title={data.HeadingMap}/>
      <CompanyDirectors cards={data.companyStackHolder} />
      
      
    </div>
    
  )
}

export default About