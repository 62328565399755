import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import CareersJobs from '../../components/CareersJobs/CareersJobs';
import ContactMap from '../../components/ContactMap/ContactMap';


const Contact = () => {
  const data = totalData[9];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <ContactMap data={data.ContactMap}/>
      <CareersJobs/>
    </div>
    
  )
}
export default Contact