import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import services from "../../JsonFiles/Services.json";
import projects from "../../JsonFiles/Projects.json";
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllCapabilities from '../../components/AllCapabilities/AllCapabilities';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import AllProjects from '../../components/AllProjects/AllProjects';


const Capabilities = () => {
  const data = totalData[5];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <AllCapabilities title={data.capabilitiesTitles} description={data.capabilitiesDescription} capabilities={services} CurrentPage={data.LinkCapabilities}/>
      <AllProjects cards={projects} />
    </div>
    
  )
}

export default Capabilities