import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const HomeIconSlider = ({ data }) => {
    const duplicatedSlides = [...data, ...data];
    const animationControls = useAnimation();
    const [ref, inView] = useInView();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (inView) {
            animationControls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 1 },
            });
        }
    }, [animationControls, inView]);

    return windowWidth >= 680 ? (
        <motion.div
            className="animated-section"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={animationControls}
        >
            <div className="relative h-full overflow-hidden py-12 bg-white mx-auto" style={{ width: "90%" }}>
                <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent after:filter after:blur-3"></div>

                <motion.div
                    className="flex"
                    animate={{
                        x: ['0%', '-100%'],
                        transition: {
                            ease: 'linear',
                            duration: 15,
                            repeat: Infinity,
                        }
                    }}
                >
                    {duplicatedSlides.map((slide, index) => (
                        <div key={index} className="flex-shrink-0" style={{ width: `${100 / data.length}%` }}>
                            <div className="flex items-center justify-center h-full xs:mr-2 sm:mr-3 md:mr-3 lg:mr-8 xl:mr-10 2xl:mr-12">
                                <img src={`/Icons/${slide.icon}`} alt={`Logo ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>
        </motion.div>
    ) : null;
};

export default HomeIconSlider;
