import React, { useState } from "react";
import "./CompanyTimeline.css";
import AssetsSVG from "./AssetsSVG";

const CompanyTimeline = ({ data, misc }) => {
  const [hoveredLayer, setHoveredLayer] = useState(null);
  console.log(data);

  const handleItemClick = (itemId) => {
    if (hoveredLayer === itemId) {
      setHoveredLayer(null); // Toggle off if already hovered
    } else {
      setHoveredLayer(itemId); // Set the hovered item id
    }
  };

  return (
    <div className="Journey-loop">
      <div className="Journey-loop-HeaderSection">
        <h1 className="Journey-loop-HeaderCompany">{misc.JourneyLoopHeader}</h1>
        <p className="Journey-loop-ParaCompany">{misc.JourneyLoopPara}</p>
      </div>
      <div className="Journey-loop-ContentAll">
        <div className="Journey-loop-LeftSection">
          <div className="Journey-loop-image">
            <AssetsSVG
              hoveredLayer={hoveredLayer}
              setHoveredLayer={setHoveredLayer}
            />
          </div>
          <div className="Journey-loop-DownHighlights">
            {data.map((item) => (
              <div
                key={item.id}
                className={`Journey-loop-DownItem ${hoveredLayer === item.id ? 'hovered' : ''}`}
                onClick={() => handleItemClick(item.id)}
              >
                <h1 className="Journey-loop-DownYear">{item.Year}</h1>
                <p className="Journey-loop-DownTitle">{item.Title}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="Journey-loop-RightSection">
          {data.map((item, index) => (
            (hoveredLayer === item.id || (hoveredLayer === null && index === 0)) && (
              <div key={item.id} className="Journey-loop-ContainBox">
                <h1 className="Journey-loop-Year">{item.Year}</h1>
                <p className="Journey-loop-Description">{item.description}</p>
                <img className="journey-image" src={item.image} alt={item.Year} />
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyTimeline;
