import React from "react";
import "./HomeAbout.css";
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const HomeAbout = ({ data,news }) => {

  const newsToShow = news.slice(0, 4);

  return (
    <div className="HomeAbout-Container">
      <div className="HomeAbout-LeftSection">
        <div className="HomeAbout-AboutUsTextSection">
          <h1 className="HomeAbout-Heading1">{data.HomeAboutHeading}</h1>
          <div className="HomeAbout-paragraphContainer">
            <p className="HomeAbout-Paragraph">{data.HomeAboutParagraph}</p>
          </div>
          <div className="HomeAboutGrid-container">
            <div
              className="HomeAboutGrid-factsGrid"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "16px",
              }}
            >
              {data.facts.map((fact, index) => (
                <div key={index} className="HomeAboutGrid-gridItem">
                  <div className="HomeAboutGrid-factNumber">{fact.number}</div>
                  <div className="HomeAboutGrid-factDescription">
                    {fact.description}
                  </div>
                  <div className="HomeAboutGrid-factExplanation">
                    {fact.explanation}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="HomeAbout-RightSection">
  <div className="HomeAbout-Bguppersection"></div>
  <div className="HomeAbout-GridContainer">
    {newsToShow.map((item) => (
      <Link key={item.id} to={`/news/${item.id}`} className="HomeAbout-CardNewsContain">
      <div className="HomeAbout-Cards">
        <h1 className="HomeAbout-CardNewsTitle">{item.title}</h1>
        <p className="HomeAbout-CardNewsDescription">{item.description}</p>
      </div>
    </Link>
    ))}
  </div>
</div>

    </div>
  );
};

export default HomeAbout;
