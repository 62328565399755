import React from 'react';
import "./AllIndustries.css";
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const IndustriesGrid = ({ Items, page }) => {
  return (
    <div className="industriesGrid-GridContainer">
      {Items.map((industries) => (
        <Link to={`/${page}/${industries.id}`} key={industries.id} className="industriesGrid-GridItem">
          
            <div className="industriesGrid-ContentSvg">
              <div className='industriesGrid-SvgInline'>
                
                <h1 className="industriesGrid-GridTitlemainSvg">{industries.title}</h1>
                <img
                  className="industriesGrid-GridIcon"
                  src={industries.icon}
                  alt={industries.title}
                />
              </div>
              <p className="industriesGrid-GridDescriptionMain">{industries.description}</p>
            </div>
        </Link>
      ))}
    </div>
  );
};

const AllIndustries = ({ title, description, Industries, CurrentPage }) => {
  return (
    <div className='AllIndustries-MainContainer'>
      <div className='AllIndustries-CapTitleStyle'>{title}</div>
      <div className='AllIndustries-CapDescriptionStyle'>{description}</div>
      <div className='AllIndustries-industriesGrid'>
        <IndustriesGrid Items={Industries} page={CurrentPage} />
      </div>
    </div>
  );
}

export default AllIndustries;
