import React, { useState, useEffect } from 'react';
import './CompanyDirectors.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CompanyDirectors = ({ cards }) => {
  const [currentDirectorIndex, setCurrentDirectorIndex] = useState(0);

  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  const goToPrevCard = () => {
    setCurrentDirectorIndex((prevIndex) => (prevIndex === 0 ? cards.length - 1 : prevIndex - 1));
  };

  const goToNextCard = () => {
    setCurrentDirectorIndex((prevIndex) => (prevIndex === cards.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 60 }}
      animate={animationControls}
    >
      <h1 className='directorcardSlider-Heading'>Our Stakeholders</h1>

      <div className="directorcard-slider">
        <button className="directorslider-button left" aria-label="Previous slide" onClick={goToPrevCard}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="directorcard-container">
          <div className="directorcard-about active">
            <div className="directorleft-section">
              <img src={cards[currentDirectorIndex].image} alt={cards[currentDirectorIndex].name} className='directorimg-slider' />
            </div>
            <div className="directorright-section">
              <div className="directorcard-content">
                <h2>{cards[currentDirectorIndex].position}</h2>
                <p className='directorParaName'>{cards[currentDirectorIndex].name}</p>
                <p className='directorParaDetails'>{cards[currentDirectorIndex].details}</p>
              </div>
            </div>
          </div>
        </div>
        <button className="directorslider-button right" aria-label="Next slide" onClick={goToNextCard}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className='directordots-container'>
        <div className="directordots">
          {cards.map((director, directorIndex) => (
            <span key={directorIndex} className={directorIndex === currentDirectorIndex ? 'directordash active' : 'directordash'} onClick={() => setCurrentDirectorIndex(directorIndex)} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default CompanyDirectors;
