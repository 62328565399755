import React from 'react'
import totalData from '../../JsonFiles/TotalData.json';
import projects from "../../JsonFiles/Projects.json";
import industries from "../../JsonFiles/Industries.json";
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllIndustries from '../../components/AllIndustries/AllIndustries';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import CareersJobs from '../../components/CareersJobs/CareersJobs';
import AllProjects from '../../components/AllProjects/AllProjects';

const Industries = () => {
  const data = totalData[6];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <AllIndustries title={data.industriesTitle} description={data.industriesDescription} Industries={industries} CurrentPage={data.LinkIndustries}/>
      <AllProjects cards={projects} />
      <CareersJobs/>
    </div>
    
  )
}

export default Industries