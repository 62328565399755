import React from 'react';
import totalData from '../../JsonFiles/TotalData.json';
import AllIntroduction from '../../components/AllIntroduction/AllIntroduction';
import AllAboutSection from '../../components/AllAboutSection/AllAboutSection';
import AllValues from '../../components/AllValues/AllValues';


const Sustainability = () => {
  const data = totalData[8];
  
  return (
    <div>
      <AllIntroduction title={data.title} description={data.description} image={data.image}/>
      <AllAboutSection data={data}/>
      <AllValues data={data}/>
    </div>
    
  )
}

export default Sustainability