import servicesData from "../../JsonFiles/Services.json"
import projectsData from "../../JsonFiles/Projects.json"
import industriesData from "../../JsonFiles/Industries.json"



export const links = [
    {
      name: "Capabilities",
      submenu: true,
      link: "/capabilities",
      sublinks: servicesData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    },
    {
      name: "Industries",
      submenu: true,
      link: "/industries",
      sublinks: industriesData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    },
    {
      name: "Projects",
      submenu: true,
      link: "/projects",
      sublinks: projectsData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    },
  ];