import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './ContactMap.css'; // Import the CSS file for styling

const ContactMap = ({data}) => {
  const containerStyle = {
    width: '100%',
    height: '400px', // Adjust height as needed
  };

  const center = {
    lat: parseFloat(data[0].ContactOfficeLat), // Use the latitude of the first office as the center
    lng: parseFloat(data[0].ContactOfficeLong),// Specific location longitude
  };

  return (
    <div className="map-container">
        <div className='map-section'>
        {data.map((office, index) => (
          <div className='map-leftsection'>
            <a href={office.ContactOfficeAddressLink} target="_blank" rel="noopener noreferrer">
          <h1 className='map-headoffice'>{office.ContactOffice}</h1>
          </a>
          <p className='map-city'>
            {office.ContactOfficeCity} 
          </p>
          <a href={office.ContactOfficeAddressLink} target="_blank" rel="noopener noreferrer">
  <p className='map-location'>
    {office.ContactOfficeAddress}
  </p>
</a>
          <p className='map-contactnumber'>
            {office.ContactOfficePhone.replace("Phone: ", "")} 
          </p>
        </div>
        ))}
            
            <div className='map-rightsection'>
            <LoadScript googleMapsApiKey="AIzaSyA9zACjF9dpj3SBD7xL7kZ43Z5fayajdIk">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={13}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
            </div>
        </div>
    </div>
  );
};

export default ContactMap;

