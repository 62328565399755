import React from 'react';
import "./NewsRoomContent.css";

const NewsRoomContent = ({ details, date }) => {
  return (
    <div className='NewsRoomContent-Container'>
      <p className='NewsRoomContent-Date'>{date}</p>
      <div className='NewsRoomContent-Details'>
        {details}
      </div>
    </div>
  );
};

export default NewsRoomContent;
